
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mainpage from "./Pages/Mainpage";
import Hakkimizda from "./Pages/Hakkimizda";
import Contact from "./Pages/Contact";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Mainpage />} />
        <Route path="/about" element={<Hakkimizda/>} />
        <Route path="/contuctus" element={<Contact/>} />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
