import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Hakkimizda() {
  return (
    <>
  <link
    href="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/css/ulass-exceptional-site-fac069.webflow.4202ca491.css"
    rel="stylesheet"
    type="text/css"
  />
  <link
    href="https://cdn.prod.website-files.com/img/favicon.ico"
    rel="shortcut icon"
    type="image/x-icon"
  />
  <link
    href="https://cdn.prod.website-files.com/img/webclip.png"
    rel="apple-touch-icon"
  />
  <Header></Header>
  <div className="section-header">
    <div className="container">
      <div className="w-layout-grid header-grid">
        <div className="header-block">
          <h1 className="hero-heading">Hakkımızda</h1>
          <p className="paragraph-regular-3">
            Bizler, karşılaşılan problemleri sistemli bir şekilde çözmeyi hayat
            felsefesi olarak benimsemiş <strong>Üniversite</strong>{" "}
            öğrencileriyiz. <br />
            <br />
            <strong>UFK Teknoloji Takımı</strong> adı altında 2023 yılından itibaren teknolojik
            yenilikler ve fikirler üretmek üzere çalışmalar yapıyoruz.
            Üyelerimiz, yapay zeka üzerine çeşitli çalışmalar yapmıştır. Bu
            alanlarda tecrübeler edinen ekip, <strong>Milli Teknoloji Hamlesi</strong> 'ne  katkı
            sağlayacak şekilde yapay zeka teknolojileri alanında projeler yapıp,
            yerli ve milli uygulamalar geliştirmeyi hedeflemektedir.
          </p>
        </div>
        <div
          id="w-node-_710de8f0-d55b-0ebf-88f0-15d583a62353-ab56c44b"
          className="header-image"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb6d3321f3306e5232f0e6_ufkkah.png"
            loading="lazy"
            width={544}
            sizes="90vw"
            alt=""
            srcSet="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb6d3321f3306e5232f0e6_ufkkah-p-500.png 500w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb6d3321f3306e5232f0e6_ufkkah-p-800.png 800w, https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb6d3321f3306e5232f0e6_ufkkah.png 833w"
            className="header-image-graphic"
          />
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</>

  )
}

export default Hakkimizda